// extracted by mini-css-extract-plugin
export var wrapper = "notfound-module--wrapper--1Jd4l";
export var codeblock = "notfound-module--codeblock--lEMPv";
export var code = "notfound-module--code--3hdYE";
export var image = "notfound-module--image--1bfTJ";
export var message = "notfound-module--message--1p6_-";
export var button = "notfound-module--button---T45r";
export var content = "notfound-module--content--wHYM1";
export var block = "notfound-module--block--38R0j";
export var title = "notfound-module--title--3VHn8";
export var text = "notfound-module--text--1TFLW";