import React from "react";
import { Layout } from "../components/Layout/Layout";
import { SEO } from "../components/Seo";
import { graphql } from "gatsby";
import { usePageContext } from "../components/Context/pageContext";
import * as styles from "../styles/pages/notfound.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { StyledLink } from "../components/StyledLink/StyledLink";
import { TermsBlockContent } from "../components/BlockContent/TermsBlockContent";

interface Props {
  data: any;
}

const NotFoundPage: React.FC<Props> = ({ data }) => {
  const { page, legalspage, notfound } = data;
  const { lang } = usePageContext();

  return (
    <Layout>
      <SEO rawMeta={page?._rawMetadata} />
      <div className={styles.wrapper}>
        <div className={styles.codeblock}>
          <div className={styles.code}>
            <div>4</div>
            <StaticImage
              src="../images/404-image.png"
              alt={"concave"}
              className={styles.image}
            />
            <div>4</div>
          </div>
          <div className={styles.message}>
            {notfound?.notFound?._rawMessage?.[lang]}
          </div>
        </div>
        <StyledLink to="/" className={styles.button}>
          HOME
        </StyledLink>
        <div className={styles.content}>
          {legalspage?.content?.termsList?.map((e: any, index: number) => {
            return (
              <div className={styles.block} key={`notfound-${index}`}>
                <div className={styles.title}>{e?._rawTitle?.[lang]}</div>
                <div className={styles.text}>
                  <TermsBlockContent blocks={e?._rawContent?.[lang]} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const QUERY = graphql`
  query NotFoundPage {
    notfound: sanitySettings {
      notFound {
        _rawMessage
      }
    }
    page: sanityHomiesPage {
      content {
        _rawTitle
        _rawSubtitle
        image {
          asset {
            gatsbyImageData
          }
        }
      }
      _rawMetadata(resolveReferences: { maxDepth: 3 })
    }

    homies: allSanityHomie {
      edges {
        node {
          name
          url
          _rawDescription
          photo {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

// <SEO title="404: Not found" />
